import { createContext, useContext, useEffect } from "react";

const usingDarkMode = () =>
  window.matchMedia("(prefers-color-scheme: dark)").matches === true;

const defaultState = {
  theme: usingDarkMode() ? "dark" : "light",
};
const ThemeContext = createContext(defaultState);

function ThemeHandler({ children }) {
  const theme = useContext(ThemeContext);

  useEffect(() => {
    document.body.className = theme.theme;
    localStorage.setItem("theme", theme.theme);
  }, [theme.theme]);
  return (
    <div className={theme.theme === "dark" ? "dark" : "light"}>{children}</div>
  );
}

export { ThemeContext, ThemeHandler };
