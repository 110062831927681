import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeHandler } from "./components/ThemeContext";
import Home from "./routes/Home";

function App() {
  return (
    <ThemeHandler>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </ThemeHandler>
  );
}

export default App;
