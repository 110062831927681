import Hello from "../components/Hello";
import Projects from "../components/Projects";
import styles from "./Home.module.css"

function Home() {
  return (
    <div className={styles.container}>
      <Hello />
      <Projects />
    </div>
  );
}

export default Home;
