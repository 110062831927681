import styles from "./Hello.module.css";
import { Github, Mail, me } from "../img";

function Hello() {
  return (

      <div className={styles.container}>
        <div>
          <span id={styles["title"]}>Hello! 👋</span>
          <br />
          <span id={styles["subtitle"]}>I'm khk4912.</span> <br />
          <div style={{ marginTop: "2vh"}}>
            <a href="https://github.com/khk4912"><Github width="1.5em" fill="grey" style={{marginRight: "0.9em"}} /></a>
            <a href="mailto:vb.net@kakao.com"><Mail width="1.5em" fill="grey" /></a>
          </div>
        </div>
        <img id={styles["profileImage"]} src={me} alt="My profile" />
      </div>

  );
}

export default Hello;
