import { noImage } from "../img";
import styles from "./ProjectElement.module.css";

function ProjectElement({ name, description, img, github, url }) {
  return (
    <div className={styles.container}>
      <img src={img ? img : noImage} alt={name}  />
        <div className={styles.info}>
            <h2> {name}</h2>
            <span>{description}</span>
            </div>
    </div>
  );
}

export default ProjectElement;
