import ProjectElement from "./ProjectElement";
import styles from "./Projects.module.css";

const Project = (name, description, img, github, url) => ({
  name,
  description,
  img,
  github,
  url,
});
const projects = [
  Project(
    "EZPaginator",
    "EZPaginator is a simple message/emebd paginator for discord.py",
    null,
    "https://github.com/khk4912/EZPaginator",
    null
  ),
  Project(
    "EZPaginator",
    "EZPaginator is a simple message/emebd paginator for discord.py",
    null,
    "https://github.com/khk4912/EZPaginator",
    null
  ),
  Project(
    "EZPaginator",
    "EZPaginator is a simple message/emebd paginator for discord.py",
    null,
    "https://github.com/khk4912/EZPaginator",
    null
  ),
  Project(
    "EZPaginator",
    "EZPaginator is a simple message/emebd paginator for discord.py",
    null,
    "https://github.com/khk4912/EZPaginator",
    null
  ),
  Project(
    "EZPaginator",
    "EZPaginator is a simple message/emebd paginator for discord.py",
    null,
    "https://github.com/khk4912/EZPaginator",
    null
  ),
  Project(
    "EZPaginator",
    "EZPaginator is a simple message/emebd paginator for discord.py",
    null,
    "https://github.com/khk4912/EZPaginator",
    null
  ),
];

function Projects() {
  return (
    <div className={`${styles.full}`}>
      <div className={styles.projects}>
        {/* {projects.map((project, index) => 
          <ProjectElement {...project} key={index} /> 
        )} */}
      </div>
    </div>
  );
}

export default Projects;
